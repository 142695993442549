import { useState } from "react";
import Swal from "sweetalert2";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
// import { GoogleLogin } from "react-google-login";

function SignIn() {
  const [showBirthdayCelebration, setShowBirthdayCelebration] = useState(false);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleLogin = () => {
    axios
      .post("/user/log", user)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("username", response.data.user.username);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("id", response.data.user._id);
          setShowBirthdayCelebration(true);
          setTimeout(() => {
            setShowBirthdayCelebration(false);
            navigate("/");
          }, 5000);
        } else {
          showErrorAlert();
        }
      })
      .catch(() => {
        showErrorAlert();
      });
  };

  const showErrorAlert = () => {
    Swal.fire({
      title: "Error!",
      text: "Please try again!",
      icon: "error",
      confirmButtonText: "Cool",
    });
  };

  const [forgotEmail, setForgotEmail] = useState(""); // State for Forgot Password email input
  const [isForgotPassword, setIsForgotPassword] = useState(false); // State to toggle between login and forgot password

  const handleForgotPassword = () => {
    axios
      .post("/mail/forgot-password", {
        email: forgotEmail,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "An email has been sent with your password!",
            icon: "success",
            confirmButtonText: "Okay",
          });
        } else {
          showErrorAlert(
            "Email not found. Please check your email and try again."
          );
        }
        console.log("Email sent:", response.data);
      })
      .catch((error) => {
        showErrorAlert("Something went wrong. Please try again.");
      });
  };

  return (
    <div>
      <div className="h-full flex bg-[#E9ECEF] justify-center items-center py-16">
        <main className="w-full font-bold max-w-md mx-auto p-6 bg-white  rounded-xl shadow-lg">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800">
                {isForgotPassword ? "Forgot Password" : "Sign In"}
              </h1>
            </div>

            <div className="mt-5">
              {isForgotPassword ? (
                <>
                  <div>
                    <label htmlFor="forgotEmail" className="block text-sm mb-2">
                      Email address
                    </label>
                    <input
                      type="email"
                      value={forgotEmail}
                      onChange={(e) => setForgotEmail(e.target.value)}
                      id="forgotEmail"
                      placeholder="Email"
                      className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm"
                      required
                    />
                  </div>
                  <button
                    onClick={handleForgotPassword}
                    className="py-3 px-4 mt-4 inline-flex justify-center items-center gap-2 rounded-md border bg-blue-500 text-white hover:bg-blue-600"
                  >
                    Send Password
                  </button>
                  <p
                    className="mt-4 text-sm text-blue-600 hover:underline cursor-pointer"
                    onClick={() => setIsForgotPassword(false)}
                  >
                    Back to Sign In
                  </p>
                </>
              ) : (
                <>
                  <div>
                    <label htmlFor="email" className="block text-sm mb-2">
                      Email address
                    </label>
                    <input
                      type="email"
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                      id="email"
                      placeholder="Email"
                      className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm"
                      required
                    />
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <label htmlFor="password" className="block text-sm">
                      Password
                    </label>
                    <p
                      className="text-sm text-blue-600 hover:underline cursor-pointer"
                      onClick={() => setIsForgotPassword(true)}
                    >
                      Forgot password?
                    </p>
                  </div>
                  <div>
                    <input
                      type="password"
                      value={user.password}
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                      id="password"
                      placeholder="Password"
                      className="py-3 mt-2 px-4 block w-full border border-gray-200 rounded-md text-sm"
                      required
                    />
                  </div>
                  <button
                    onClick={handleLogin}
                    className="py-3 px-4 mt-4 w-full inline-flex justify-center items-center gap-2 rounded-md border bg-blue-500 text-white hover:bg-blue-600"
                  >
                    Sign In
                  </button>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
      {showBirthdayCelebration && <Confetti />}
    </div>
  );
}

export default SignIn;
